import React from 'react';
import { Link as AnchorLink } from 'react-scroll';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';

function NavLink({ children, offset, to, onClick }) {
  const location = useLocation();
  const { pathname } = location;

  const anchorLinkProps = {
    activeClass: 'current',
    spy: true,
    smooth: true,
    duration: 1000,
    offset: offset ?? -78,
    onClick,
  };

  const isInHome = pathname === '/';

  return isInHome ? (
    <AnchorLink {...anchorLinkProps} to={to}>
      {children}
    </AnchorLink>
  ) : (
    <Link to={`/?section=${to}`}>{children}</Link>
  );
}

export default NavLink;
