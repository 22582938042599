import React from 'react'
import Layout from './src/widgets/Layout'

import '@fontsource/dosis/400.css';
import '@fontsource/dosis/500.css';
import '@fontsource/dosis/600.css';
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';

const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export {
  wrapPageElement
}