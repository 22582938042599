import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { Link, navigate } from 'gatsby';
import { scroller } from 'react-scroll';

import './styles.scss';
import NavLink from './Link';

function Navbar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const location = useLocation();
  const { pathname, search } = location;

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setIsScrolled(window.pageYOffset > 450);
    });
  }, []);

  useEffect(() => {
    if (search) {
      const section = search.split('?section=')[1];
      scroller.scrollTo(section, {
        duration: 1000,
        delay: 0,
        offset: -78,
        smooth: 'easeInOutQuart',
      });
      navigate('/');
    }
  }, [search]);

  const onLinkClick = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    onLinkClick();
  }, [pathname]);

  return (
    <header className="header">
      <nav
        className={`navbar navbar-default navbar-fixed-top ${
          isScrolled ? 'opaque' : ''
        }`}
      >
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <Link className="navbar-brand" to="/">
              <StaticImage
                className="logo"
                src="../../images/logo.png"
                alt="timesheet logo"
              />
            </Link>

            <button
              className="navbar-toggler collapsed"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setIsExpanded(!isExpanded);
              }}
            >
              <span className="navbar-toggler-icon">
                <i className="fas fa-bars"></i>
              </span>
            </button>

            <div
              className={`navbar-collapse collapse ${isExpanded ? 'show' : 'hide'}`}
              id="navbarCollapse"
            >
              <ul className="navbar-nav ml-auto">
                <li>
                  <NavLink onClick={onLinkClick} to="home">
                    Home
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={onLinkClick} to="about">
                    About
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={onLinkClick} to="pricing">
                    Pricing
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={onLinkClick} to="showcase">
                    Showcase
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={onLinkClick} to="features">
                    Features
                  </NavLink>
                </li>

                <li>
                  <NavLink onClick={onLinkClick} to="contact">
                    Contact
                  </NavLink>
                </li>

                <li>
                  <div className="separator" />
                </li>

                <li>
                  <Link activeClassName="current" to="/blog">
                    Blog
                  </Link>
                </li>

                <li>
                  <Link activeClassName="current" to="/tutorials">
                    Tutorials
                  </Link>
                </li>

                <li className="discover-link">
                  <a
                    href="https://app.timesheet.global/login"
                    className="external discover-btn"
                  >
                    Sign In
                  </a>
                </li>

                <li className="discover-link">
                  <a
                    href="https://app.timesheet.global/signup"
                    className="external discover-btn sign-up-btn"
                  >
                    Sign Up
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </nav>
    </header>
  );
}

export default Navbar;
